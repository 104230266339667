import { Box, SxProps } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ChangeEvent, FC } from 'react';

import { WeightUnit } from 'constants/enums';

import { Unit, WeightField } from './index.styled';

interface WeightSelectorProps {
  selectedWeight: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  kioskMode?: boolean;
  sx?: SxProps;
  weightUnit?: WeightUnit;
}

const WeightSelector:FC<WeightSelectorProps> = ({
  selectedWeight,
  onChange,
  onBlur,
  disabled = false,
  kioskMode = false,
  sx = {},
  weightUnit = WeightUnit.G,
}) => (
  <Box display="flex" alignItems="center" sx={sx}>
    <WeightField
      label=""
      value={selectedWeight}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      size="small"
      decimalScale={3}
      kioskMode={kioskMode}
      fullWidth={kioskMode}
      suffix={kioskMode ? ` ${weightUnit.toLowerCase()}` : ''}
    />
    {!kioskMode && (
      <Unit>
        <Typography>
          {weightUnit}
        </Typography>
      </Unit>
    )}
  </Box>
);

export default WeightSelector;
