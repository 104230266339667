import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { Box, Typography } from '@mui/material';
import {
  ChangeEvent, FC, useContext, useState,
} from 'react';
import { toast } from 'react-toastify';

import CustomButton from 'components/CustomButton';
import WeightSelector from 'components/WeightSelector';
import { CartDispatchContext } from 'context/CartContext';

import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';

import { Bounty } from 'types/bounty.interface';
import { getTopLevelVariant } from 'utils/priceUtils';

interface ProductWeightSelectorProps {
  bounty: Bounty;
}

const ProductWeightSelector: FC<ProductWeightSelectorProps> = ({ bounty }) => {
  const { addItemWithWeight } = useContext(CartDispatchContext);
  const { weightUnit } = getTopLevelVariant(bounty?.product) || {};
  const { handleTrackAddItemToCart } = useGoogleAnalytics();
  const [currentWeight, setCurrentWeight] = useState<string>(bounty?.product?.sortWeight?.toString() || '');

  const handleInputChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setCurrentWeight(value);
  };

  const handleAddToCart = () => {
    addItemWithWeight({
      bounty,
      weight: currentWeight,
      onSuccess: () => {
        toast.success('Product added to cart!');
        handleTrackAddItemToCart({ bounty, weight: currentWeight });
      },
    });
  };

  return (
    <Box display="flex" alignItems="center" gap={2}>
      <Typography variant="body1">
        WEIGHT
      </Typography>

      <WeightSelector
        selectedWeight={currentWeight}
        onChange={handleInputChange}
        weightUnit={weightUnit}
      />

      <CustomButton
        id="button-addToCart"
        variant="contained"
        onClick={handleAddToCart}
        startIcon={<AddShoppingCartIcon />}
        disabled={+currentWeight === 0 || !currentWeight}
      >
        Add to cart
      </CustomButton>
    </Box>
  );
};

export default ProductWeightSelector;
