import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {
  Box, IconButton, Typography, useTheme,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  ChangeEvent, FC, useContext, useState,
} from 'react';
import { toast } from 'react-toastify';

import IncrementWrapper from 'components/IncrementWrapper';
import QuantitySelector from 'components/QuantitySelector';
import WeightSelector from 'components/WeightSelector';
import { CartDispatchContext } from 'context/CartContext';

import { ProductInfo } from 'types/cart.interface';
import { extractNumberFromNumericFormat } from 'utils/numbers';
import { shouldUseProductWeight } from 'utils/productUtils';
import { reportAlert } from 'utils/utils';

interface ProductQuantitySelectorProps {
  product: ProductInfo;
  kioskMode: boolean;
  onOpen: (isOpen: boolean) => void;
  isDisabled: boolean;
  onRemove: () => void;
  isProductUpdated?: boolean;
  onChange?: (productId: string) => void;
  updatedQuantity?: number;
}

const ProductQuantitySelector: FC<ProductQuantitySelectorProps> = ({
  product,
  kioskMode,
  onOpen,
  isDisabled,
  onRemove,
  isProductUpdated = false,
  onChange,
  updatedQuantity = 0,
}) => {
  const theme = useTheme();
  const { setQuantity, setWeight } = useContext(CartDispatchContext);
  const matchesScreenSizeSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [currentWeight, setCurrentWeight] = useState<string>(product?.weight || '');
  const isOutOfStock = Boolean(isProductUpdated && updatedQuantity <= 0);
  const { availableQuantity = 0, sortWeight } = product;
  const selectedQuantity = isOutOfStock ? 0 : product.quantity;
  const isUsingWeight = shouldUseProductWeight(product);

  const handleQuantityChanges = (newQuantity: number) => {
    if (newQuantity <= 0) {
      onOpen(true);
    } else {
      setQuantity(product.id, newQuantity);
    }

    if (onChange) {
      onChange(product.id);
    }
  };

  const handleDecrease = () => {
    if (isUsingWeight) {
      const formattedWeight = extractNumberFromNumericFormat({ value: currentWeight, hasSuffix: kioskMode });
      const newWeight = (formattedWeight - 0.5)?.toString();

      if (formattedWeight > 0.5) {
        setCurrentWeight(newWeight);
        handleSaveWeight(newWeight);
      } else {
        onOpen(true);
      }

      return;
    }

    if (selectedQuantity - 1 <= 0) {
      onOpen(true);
      return;
    }

    setQuantity(product.id, selectedQuantity > 0 ? selectedQuantity - 1 : 0);
  };

  const handleIncrease = () => {
    if (isUsingWeight) {
      const formattedWeight = extractNumberFromNumericFormat({ value: currentWeight, hasSuffix: kioskMode });
      const newWeight = (formattedWeight + 0.5)?.toString();

      if (sortWeight && availableQuantity * sortWeight >= formattedWeight + 0.5) {
        setCurrentWeight(newWeight);
        handleSaveWeight(newWeight);
      }

      return;
    }

    if (availableQuantity === selectedQuantity) {
      toast.warning('You\'ve reached the maximum available amount for this product!');
      return;
    }

    setQuantity(product.id, selectedQuantity + 1);
  };

  const handleWeightChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => setCurrentWeight(value);

  const handleBlurWeight = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => handleSaveWeight(value);

  const handleSaveWeight = (value: string) => {
    if (sortWeight && availableQuantity * sortWeight < +value) {
      toast.warning('You\'ve reached the maximum available weight for this product!');
      setCurrentWeight((availableQuantity * sortWeight)?.toString());
      return;
    }

    if (!sortWeight) {
      reportAlert(`Cannot change product weight, missing sortWeight:\n${JSON.stringify(product)}`);
      toast.info('The product is missing important information, we can\'t change the weight.');
      return;
    }

    if (!value || +value === 0) {
      onRemove();
      return;
    }

    setWeight(product?.id, value, sortWeight);
  };

  const checkIfOptionIsDisabled = (quantity: number) => {
    if (!isProductUpdated) {
      return false;
    }

    return Math.max(0, quantity) > Math.max(0, updatedQuantity);
  };

  if (kioskMode) {
    return (
      <Box display="flex" alignItems="center" gap={matchesScreenSizeSm ? 1 : 2}>
        <Box width="250px">
          <IncrementWrapper
            onDecrease={handleDecrease}
            onIncrease={handleIncrease}
          >
            {isUsingWeight
              ? (
                <WeightSelector
                  selectedWeight={currentWeight}
                  weightUnit={product?.weightUnit}
                  onChange={handleWeightChange}
                  onBlur={handleBlurWeight}
                  kioskMode
                  disabled={isOutOfStock || isDisabled}
                />
              ) : (
                <Typography p={1} textAlign="center" flexGrow={1}>
                  {selectedQuantity}
                </Typography>
              )}
          </IncrementWrapper>
        </Box>
        <IconButton
          disabled={isOutOfStock || isDisabled}
          sx={{ p: 0.5 }}
          onClick={() => onOpen(true)}
          id="iconButton-removeItem"
        >
          <CancelOutlinedIcon sx={{ color: 'general.lightGrey1' }} />
        </IconButton>
      </Box>
    );
  }

  return (
    <Box display="flex" alignItems="center" gap={matchesScreenSizeSm ? 1 : 2}>
      {isUsingWeight
        ? (
          <>
            {!matchesScreenSizeSm && (
              <Typography variant="body1">
                Weight
              </Typography>
            )}
            <WeightSelector
              onChange={handleWeightChange}
              onBlur={handleBlurWeight}
              selectedWeight={currentWeight}
              weightUnit={product?.weightUnit}
              disabled={isOutOfStock || isDisabled}
            />
          </>
        ) : (
          <>
            {!matchesScreenSizeSm && (
              <Typography variant="body1">
                Quantity
              </Typography>
            )}
            <QuantitySelector
              allowZero
              disabled={isOutOfStock || isDisabled}
              selectedQuantity={selectedQuantity}
              handleQuantity={handleQuantityChanges}
              availableQuantity={availableQuantity}
              checkIfOptionIsDisabled={checkIfOptionIsDisabled}
            />
          </>
        )}

      <IconButton
        disabled={isOutOfStock || isDisabled}
        sx={{ p: 0.5 }}
        onClick={() => onOpen(true)}
        id="iconButton-removeItem"
      >
        <CancelOutlinedIcon sx={{ color: 'general.lightGrey1' }} />
      </IconButton>
    </Box>
  );
};

export default ProductQuantitySelector;
