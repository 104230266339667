import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { Box, Tooltip, Typography } from '@mui/material';
import {
  FC, MouseEvent, useMemo, useState,
} from 'react';

import Brand from 'components/Brand';
import Dot from 'components/Dot';
import FreeTag from 'components/FreeTag';
import {
  StyledSection, StyledImage, StyledPriceBox, StyledAddToCard,
} from 'components/ProductCard/index.styled';
import ProductCardOverlay from 'components/ProductCardOverlay';
import ProductPrice from 'components/ProductPrice';
import ProductWeight from 'components/ProductWeight';

import { useKiosk } from 'hooks/useKiosk';

import { Bounty } from 'types/bounty.interface';
import { ApiLocation } from 'types/companyLocations.interface';
import { textTruncate } from 'utils/formatters';
import { getPrices, getTopLevelVariant, isFree } from 'utils/priceUtils';
import {
  formatChemicalComposition,
  formatProductWeight,
  getCoverUrl,
  getManufacturer,
  getProductStrainType,
} from 'utils/productUtils';
import { getPublicUrlForImage } from 'utils/publicUrl';

interface ProductCardProps {
  bounty: Bounty;
  store?: ApiLocation;
  onAdd?: (bounty: Bounty) => unknown;
  hasAddButton?: boolean;
  isBrandDisabled?: boolean;
  isDescriptionVisible?: boolean;
}

const ProductCard:FC<ProductCardProps> = ({
  bounty,
  onAdd,
  store,
  hasAddButton = false,
  isBrandDisabled = false,
  isDescriptionVisible = false,
}) => {
  const { kioskMode } = useKiosk();
  const { product, tags } = bounty;
  const { price, msrp } = getPrices(product, store);
  const isProductFree = isFree(price);
  const manufacturer = getManufacturer(product);
  const { quantity, weightUnit } = getTopLevelVariant(product) || {};
  const productQuantity = Math.max(0, quantity || 0);
  const isOutOfStock = productQuantity <= 0;
  const strainType = tags?.tags ? getProductStrainType(tags?.tags) : null;
  const [showOverlay, setShowOverlay] = useState<boolean>(false);

  const coverUrl = useMemo(() => (
    getCoverUrl(bounty?.id, product)
  ), [bounty.id]);

  const chemicalCompositionString = useMemo(() => (
    formatChemicalComposition(product)
  ), [product?.chemicalComposition]);

  const handleShowOverlay = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setShowOverlay(true);
  };

  const addToCart = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();

    if (onAdd) {
      onAdd(bounty);
    }
  };

  const handleCloseOverlay = () => setShowOverlay(false);

  const renderPrice = () => {
    if (isOutOfStock) {
      return <Typography fontWeight={700} variant="body2" color="error">Out of stock</Typography>;
    }

    if (isProductFree) {
      return <FreeTag fontWeight={700} variant="body2" />;
    }

    return <ProductPrice price={price} msrp={msrp} fontWeight={700} variant="body1" />;
  };

  return (
    <Box position="relative" height="100%">
      <StyledSection>
        <Box margin="0 auto" height={150}>
          <StyledImage
            component="img"
            src={coverUrl || getPublicUrlForImage('images/placeholder_image.jpg')}
            alt={product?.name}
          />
        </Box>

        <Box my={1}>
          {!!product?.name && (
            <Typography fontWeight="700" gutterBottom>
              {product?.name}
            </Typography>
          )}
          {!!manufacturer && (
            <Brand brand={manufacturer} disabled={isBrandDisabled} />
          )}
          {product?.description && isDescriptionVisible && (
            <Typography color="textSecondary" variant="body2" my={1}>
              {textTruncate(product?.description, 50)}
            </Typography>
          )}
          {(strainType || chemicalCompositionString) && (
            <Typography component="div" color="textSecondary" variant="body2" my={1}>
              {!!strainType && strainType.displayName}
              {!!strainType && !!chemicalCompositionString && <Dot display="inline-block" mb={0.5} mx={1} />}
              {!!chemicalCompositionString && chemicalCompositionString}
            </Typography>
          )}
        </Box>

        <StyledPriceBox>
          <Box display="flex" alignItems="center" gap={1}>
            {renderPrice()}
            {product?.sortWeight && (
              <>
                <Dot />
                <ProductWeight
                  fontWeight={700}
                  variant="body1"
                  weight={formatProductWeight(product.sortWeight)}
                  weightUnit={weightUnit}
                />
              </>
            )}
          </Box>
          {hasAddButton && (
            <Box>
              <Tooltip title={isOutOfStock ? 'Out of stock' : 'Add to cart'}>
                <span>
                  <StyledAddToCard
                    id="iconButton-addToCard"
                    onClick={kioskMode ? handleShowOverlay : addToCart}
                    disabled={isOutOfStock}
                  >
                    <AddShoppingCartIcon fontSize="small" />
                  </StyledAddToCard>
                </span>
              </Tooltip>
            </Box>
          )}
        </StyledPriceBox>
      </StyledSection>

      {showOverlay && onAdd && hasAddButton && (
        <Box onClick={(e) => e.preventDefault()}>
          <ProductCardOverlay onBack={handleCloseOverlay} bounty={bounty} />
        </Box>
      )}
    </Box>
  );
};

export default ProductCard;
