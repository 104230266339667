import { Typography, Box } from '@mui/material';
import { isNil } from 'lodash';
import {
  FC, useContext, useMemo, useState,
} from 'react';
import { toast } from 'react-toastify';

import Brand from 'components/Brand';
import FreeTag from 'components/FreeTag';
import Price from 'components/Price';
import RemoveProductConfirmation from 'components/RemoveProductConfirmation';
import SummaryStockInfo from 'components/SummaryStockInfo';
import UnstyledLink from 'components/UnstyledLink';
import {
  StyledBox,
  StyledDescription,
  StyledImage,
  StyledPrice,
} from 'containers/CartProduct/index.styled';
import ProductQuantitySelector from 'containers/CartProduct/ProductQuantitySelector';
import { CartDispatchContext } from 'context/CartContext';

import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { useKiosk } from 'hooks/useKiosk';
import { getProductDetailsRoute } from 'hooks/useRouting';

import { CART_PRODUCT_COVER_SIZE } from 'constants/cover';
import { ProductInfo } from 'types/cart.interface';
import { getProductCover } from 'utils/attachmentsUtils';
import { calculateCartTotal } from 'utils/cartUtils';
import { getPublicUrlForImage } from 'utils/publicUrl';

interface CartProductProps {
  product: ProductInfo;
  isProductUpdated?: boolean;
  wasAdjusted?: boolean;
  updatedQuantity?: number;
  handleChange?: (productId: string) => void;
  isDisabled?: boolean;
}

const CartProduct:FC<CartProductProps> = (props) => {
  const {
    product, updatedQuantity = 0, isProductUpdated, handleChange,
    wasAdjusted, isDisabled = false,
  } = props;
  const { kioskMode } = useKiosk();
  const [isOpen, setIsOpen] = useState(false);
  const { removeItem } = useContext(CartDispatchContext);
  const { handleTrackRemoveFromCart } = useGoogleAnalytics();

  const totalPrice = calculateCartTotal([product]);
  const isOutOfStock = Boolean(isProductUpdated && updatedQuantity <= 0);
  const updatedQuantityLabel = isOutOfStock
    ? 'Sorry, the item is no longer available due to stock.'
    : `The quantity has changed, there are only ${updatedQuantity} left.`;
  const { availableQuantity = 0, manufacturer, balances = {} } = product;
  const coverUrl = useMemo(() => {
    const cover = getProductCover(
      product.imageUrl,
      CART_PRODUCT_COVER_SIZE.w,
      CART_PRODUCT_COVER_SIZE.h,
      product.imageCdn,
    );
    return cover.cdnUrl || cover.url;
  }, [product.id]);

  const handleClose = () => setIsOpen(false);

  const handleRemove = () => {
    removeItem(product.id);
    handleTrackRemoveFromCart(product);
    toast.success('Product was removed from cart!');
  };

  const handleSubmit = () => {
    handleRemove();
    handleClose();
  };

  return (
    <StyledBox hasBottomBorder={!(balances && Object.keys(balances).length > 0)}>
      <Box sx={{ opacity: isOutOfStock ? 0.5 : 1 }}>
        <Box display="flex">
          <StyledImage
            src={coverUrl || getPublicUrlForImage('images/placeholder_image.jpg')}
            alt={product?.name}
          />
          <Box pb={1} maxWidth="500px" width="100%">
            {isOutOfStock || isDisabled
              ? (
                <Typography variant="body1" fontWeight={600}>
                  {product?.name}
                </Typography>
              ) : (
                <UnstyledLink to={getProductDetailsRoute(product.bountyId)}>
                  <Typography variant="body1" fontWeight={600}>
                    {product?.name}
                  </Typography>
                </UnstyledLink>
              )}
            {!!manufacturer && (
              <Brand brand={manufacturer} sx={{ mb: 1 }} disabled={isOutOfStock || isDisabled} />
            )}
            <StyledDescription variant="body1" color="textSecondary">
              {product?.description}
            </StyledDescription>
          </Box>
        </Box>

        <StyledPrice>
          <Box display="flex" alignItems="center">
            {availableQuantity > 0
              ? (
                <ProductQuantitySelector
                  product={product}
                  kioskMode={kioskMode}
                  isProductUpdated={isProductUpdated}
                  onOpen={setIsOpen}
                  isDisabled={isDisabled}
                  onRemove={handleRemove}
                  onChange={handleChange}
                  updatedQuantity={updatedQuantity}
                />
              ) : (
                <Typography variant="body2" color="error">
                  Out of stock
                </Typography>
              )}
          </Box>
          {totalPrice
            ? (
              <Price
                fontWeight={700}
                variant="body1"
                price={totalPrice}
                weight={!isNil(product?.weight) ? product?.sortWeight : null}
              />
            ) : <FreeTag />}
        </StyledPrice>
      </Box>

      {(isProductUpdated && !wasAdjusted) && (
        <SummaryStockInfo
          updatedQuantityLabel={updatedQuantityLabel}
          onRemove={handleRemove}
          isOutOfStock={isOutOfStock}
        />
      )}

      {isOpen && (
        <RemoveProductConfirmation
          isOpen={isOpen}
          onClose={handleClose}
          onSubmit={handleSubmit}
        />
      )}
    </StyledBox>
  );
};

export default CartProduct;
