export const extractNumberFromNumericFormat = (
  { value, hasSuffix = false }: { value?: string | null; hasSuffix: boolean },
) => {
  if (!value) {
    return 0;
  }

  let amount = value;

  if (hasSuffix) {
    amount = amount?.split(' ')?.[0];
  }

  amount = amount?.replaceAll(',', '');
  return amount ? +amount : 0;
};
