import { Box, Typography } from '@mui/material';
import { FC, useMemo } from 'react';

import Brand from 'components/Brand';
import CertificateOfAuthenticity from 'components/CertificateOfAuthenticity';
import ChemicalInformation from 'components/ChemicalInformation';
import Dot from 'components/Dot';
import FreeTag from 'components/FreeTag';
import ProductPrice from 'components/ProductPrice';
import ProductRating from 'components/ProductRating';
import ProductWeight from 'components/ProductWeight';
import StrainType from 'containers/ProductDetailsPage/StrainType';

import { Bounty } from 'types/bounty.interface';
import { ApiLocation } from 'types/companyLocations.interface';
import { Product } from 'types/product.interface';
import { Rating } from 'types/rating.interface';
import { getPrices, getTopLevelVariant, isFree } from 'utils/priceUtils';
import {
  formatProductWeight, getManufacturer, getProductStrainType, getSortedChemicals,
} from 'utils/productUtils';
import { getRatingScore } from 'utils/ratingUtils';
import { isTestChannel } from 'utils/utils';

interface ProductInformationProps {
  bounty: Bounty;
  store?: ApiLocation;
}

const ProductInformation:FC<ProductInformationProps> = ({ bounty, store }) => {
  const { product = {} as Product, rating = {} as Rating, tags } = bounty;
  const { chemicalComposition, certificateOfAuthenticityUrl } = product;
  const { price, msrp } = getPrices(product, store);
  const isProductFree = isFree(price);
  const manufacturer = getManufacturer(product);
  const strainType = tags?.tags ? getProductStrainType(tags?.tags) : null;
  const { weightUnit } = getTopLevelVariant(product) || {};

  const sortedChemicals = useMemo(() => (
    chemicalComposition
      ? getSortedChemicals(chemicalComposition)
      : []
  ), [chemicalComposition]);

  return (
    <div>
      <Box mb={1}>
        <Typography variant="h5" fontWeight="500" gutterBottom>
          {product?.name}
        </Typography>
        {manufacturer && (
          <Brand brand={manufacturer} />
        )}
      </Box>

      <Typography gutterBottom color="textSecondary">
        {product?.description}
      </Typography>

      {isTestChannel() && (
        <ProductRating ratingStats={getRatingScore(rating)} />
      )}

      {certificateOfAuthenticityUrl && (
        <CertificateOfAuthenticity url={certificateOfAuthenticityUrl} />
      )}

      {strainType && <StrainType strainType={strainType} />}
      {sortedChemicals && sortedChemicals.length > 0 && (
        <ChemicalInformation chemicalComposition={sortedChemicals} sx={{ mt: 1 }} />
      )}

      <Box my={2} display="flex" alignItems="center" gap={1}>
        {isProductFree
          ? <FreeTag fontWeight={700} variant="body1" />
          : <ProductPrice price={price} msrp={msrp} fontWeight={500} variant="h5" />}
        {product?.sortWeight && (
          <>
            <Dot />
            <ProductWeight
              fontWeight={500}
              variant="h5"
              weight={formatProductWeight(product.sortWeight)}
              weightUnit={weightUnit}
            />
          </>
        )}
      </Box>
    </div>
  );
};

export default ProductInformation;
