// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { FilterType, PeriodType, UnitOfMeasurementType } from 'constants/enums';
import { Bounty } from 'types/bounty.interface';
import { ProductInfo } from 'types/cart.interface';

import placeholderImage from 'assets/images/placeholder_image.jpg';

export const MOCK_REVIEW_STATS = {
  totalFiveStarRatings: 2,
  totalFourStarRatings: 1,
  averageRating: 4.7,
  total: 3,
};

export const MOCK_PREVIEW_PRODUCTS:Bounty[] = [
  {
    id: '1',
    shortId: 'p4lhtcl',
    name: 'Lorem ipsum',
    description: {
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. '
        + 'Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s,'
        + ' when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    },
    type: 'PRODUCT',
    state: 'ACTIVE',
    stats: {
      pendingChats: 0,
      talking: 0,
      reach: 0,
      rebounties: 0,
      subBounties: 0,
      sharesSent: 0,
      sharesRecv: 0,
      participantCount: 0,
      pendingResponseCount: 0,
      acceptedResponseCount: 0,
      responseCount: 0,
      commentCount: 0,
      likesCount: 0,
      inviteeCount: 0,
      viewsCount: 15,
    },
    rating: {
      totalStars: 0,
      ratingCount: 0,
      starPointsValue: 1,
    },
    attachments: {
      attachments: {
        test: {
          id: 'test',
          url: placeholderImage,
          displayStyle: 'ATTACH',
          visibility: 'ALL',
          type: 'OTHER',
          meaning: 'PRODUCT',
          mimeType: 'image/png',
          order: '',
          size: 123,
        },
      },
    },
    product: {
      chemicalComposition: [
        {
          iconName: 'mir:filter_vintage',
          name: 'CBD',
          value: 5,
          uom: 'mg',
        },
        {
          iconName: 'mir:filter_vintage',
          name: 'CBD Per Serving',
          value: 5,
          uom: 'mg',
        },
        {
          iconName: 'mir:spa',
          name: 'THC',
          value: 5,
          uom: 'mg',
        },
        {
          iconName: 'mir:spa',
          name: 'THC Per Serving',
          value: 5,
          uom: 'mg',
        },
      ],
      merchant: {
        avatar: '',
        id: '-NGt3YJvQZTTUAYuliCC',
        name: 'Lorem ipsum',
        kind: 'COMPANY',
      },
      merchantId: '-NGt3YJvQZTTUAYuliCC',
      merchantSku: 'sku_1032',
      handle: '1032',
      kind: 'PRODUCT',
      name: 'Lorem ipsum',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. '
        + 'Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s,'
        + ' when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      published: true,
      imageUrl: placeholderImage,
      imageAltText: 'Lorem ipsum',
      variants: {
        sku_1032: {
          sku: 'sku_1032',
          manufacturer: 'Lorem ipsum',
          quantity: 100,
          price: {
            mainPrice: {
              money: {
                amount: '15.00',
                currency: 'USD',
              },
            },
          },
          imageUrl: placeholderImage,
        },
      },
    },
    createdAt: 1668478551867,
    updatedAt: 1674137617674,
    editedAt: 1674137617673,
    postedAt: 1668478552111,
    visibility: 'GLOBAL',
    bountyCategories: {
      '1022:1002': {
        rootCode: '1022',
        categoryCode: '1002',
        categoryName: 'Pre-Rolls',
      },
    },
    creatorUpdatedAtSortKey: '-NGt3YJvQZTTUAYuliCC-GG-NN-7FFFFE7A359F3AF5-A',
    typeCreatedAtSortKey: 'PRODUCT-GG-NN-7FFFFE7A359F3AF5-A',
    typePopularitySortKey: 'PRODUCT-GG-NN-7FFFFFFFFFFFFFF0-A',
    typeDiscussedSortKey: 'PRODUCT-GG-NN-7FFFFFFFFFFFFFFF-A',
    listCreatedAtSortKey: 'STREAM-GG-NN-7FFFFE7A359F3AF5-A',
    listPopularitySortKey: 'STREAM-GG-NN-7FFFFFFFFFFFFFFF-A',
    listDiscussedSortKey: 'STREAM-GG-NN-7FFFFFFFFFFFFFFF-A',
    streamCreatedAtSortKey: 'STREAM-GG-NN-7FFFFE7A359F3AF5-A',
    streamPopularitySortKey: 'STREAM-GG-NN-7FFFFFFFFFFFFFFF-A',
    streamDiscussedSortKey: 'STREAM-GG-NN-7FFFFFFFFFFFFFFF-A',
  },
  {
    id: '2',
    shortId: 'p4lhtcl',
    name: 'Lorem ipsum',
    description: {
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. '
        + 'Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s,'
        + ' when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    },
    type: 'PRODUCT',
    state: 'ACTIVE',
    stats: {
      pendingChats: 0,
      talking: 0,
      reach: 0,
      rebounties: 0,
      subBounties: 0,
      sharesSent: 0,
      sharesRecv: 0,
      participantCount: 0,
      pendingResponseCount: 0,
      acceptedResponseCount: 0,
      responseCount: 0,
      commentCount: 0,
      likesCount: 0,
      inviteeCount: 0,
      viewsCount: 15,
    },
    rating: {
      totalStars: 0,
      ratingCount: 0,
      starPointsValue: 1,
    },
    attachments: {
      attachments: {
        test: {
          id: 'test',
          url: placeholderImage,
          displayStyle: 'ATTACH',
          visibility: 'ALL',
          type: 'OTHER',
          meaning: 'PRODUCT',
          mimeType: 'image/png',
          order: '',
          size: 123,
        },
      },
    },
    product: {
      merchant: {
        avatar: '',
        id: '-NGt3YJvQZTTUAYuliCC',
        name: 'Lorem ipsum',
        kind: 'COMPANY',
      },
      merchantId: '-NGt3YJvQZTTUAYuliCC',
      merchantSku: 'sku_1032',
      handle: '1032',
      kind: 'PRODUCT',
      name: 'Lorem ipsum',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. '
        + 'Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s,'
        + ' when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      published: true,
      imageUrl: placeholderImage,
      imageAltText: 'Lorem ipsum',
      variants: {
        sku_1032: {
          sku: 'sku_1032',
          manufacturer: 'Lorem ipsum',
          quantity: 100,
          price: {
            mainPrice: {
              money: {
                amount: '15.00',
                currency: 'USD',
              },
            },
          },
          imageUrl: placeholderImage,
        },
      },
    },
    createdAt: 1668478551867,
    updatedAt: 1674137617674,
    editedAt: 1674137617673,
    postedAt: 1668478552111,
    visibility: 'GLOBAL',
    bountyCategories: {
      '1022:1002': {
        rootCode: '1022',
        categoryCode: '1002',
        categoryName: 'Pre-Rolls',
      },
    },
    creatorUpdatedAtSortKey: '-NGt3YJvQZTTUAYuliCC-GG-NN-7FFFFE7A359F3AF5-A',
    typeCreatedAtSortKey: 'PRODUCT-GG-NN-7FFFFE7A359F3AF5-A',
    typePopularitySortKey: 'PRODUCT-GG-NN-7FFFFFFFFFFFFFF0-A',
    typeDiscussedSortKey: 'PRODUCT-GG-NN-7FFFFFFFFFFFFFFF-A',
    listCreatedAtSortKey: 'STREAM-GG-NN-7FFFFE7A359F3AF5-A',
    listPopularitySortKey: 'STREAM-GG-NN-7FFFFFFFFFFFFFFF-A',
    listDiscussedSortKey: 'STREAM-GG-NN-7FFFFFFFFFFFFFFF-A',
    streamCreatedAtSortKey: 'STREAM-GG-NN-7FFFFE7A359F3AF5-A',
    streamPopularitySortKey: 'STREAM-GG-NN-7FFFFFFFFFFFFFFF-A',
    streamDiscussedSortKey: 'STREAM-GG-NN-7FFFFFFFFFFFFFFF-A',
  },
  {
    id: '3',
    shortId: 'p4lhtcl',
    name: 'Lorem ipsum',
    description: {
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. '
        + 'Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s,'
        + ' when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    },
    type: 'PRODUCT',
    state: 'ACTIVE',
    stats: {
      pendingChats: 0,
      talking: 0,
      reach: 0,
      rebounties: 0,
      subBounties: 0,
      sharesSent: 0,
      sharesRecv: 0,
      participantCount: 0,
      pendingResponseCount: 0,
      acceptedResponseCount: 0,
      responseCount: 0,
      commentCount: 0,
      likesCount: 0,
      inviteeCount: 0,
      viewsCount: 15,
    },
    rating: {
      totalStars: 0,
      ratingCount: 0,
      starPointsValue: 1,
    },
    attachments: {
      attachments: {
        test: {
          id: 'test',
          url: placeholderImage,
          displayStyle: 'ATTACH',
          visibility: 'ALL',
          type: 'OTHER',
          meaning: 'PRODUCT',
          mimeType: 'image/png',
          order: '',
          size: 123,
        },
      },
    },
    product: {
      merchant: {
        avatar: '',
        id: '-NGt3YJvQZTTUAYuliCC',
        name: 'Lorem ipsum',
        kind: 'COMPANY',
      },
      merchantId: '-NGt3YJvQZTTUAYuliCC',
      merchantSku: 'sku_1032',
      handle: '1032',
      kind: 'PRODUCT',
      name: 'Lorem ipsum',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. '
        + 'Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s,'
        + ' when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      published: true,
      imageUrl: placeholderImage,
      imageAltText: 'Lorem ipsum',
      variants: {
        sku_1032: {
          sku: 'sku_1032',
          manufacturer: 'Lorem ipsum',
          quantity: 100,
          price: {
            mainPrice: {
              money: {
                amount: '15.00',
                currency: 'USD',
              },
            },
          },
          imageUrl: placeholderImage,
        },
      },
    },
    createdAt: 1668478551867,
    updatedAt: 1674137617674,
    editedAt: 1674137617673,
    postedAt: 1668478552111,
    visibility: 'GLOBAL',
    bountyCategories: {
      '1022:1002': {
        rootCode: '1022',
        categoryCode: '1002',
        categoryName: 'Pre-Rolls',
      },
    },
    creatorUpdatedAtSortKey: '-NGt3YJvQZTTUAYuliCC-GG-NN-7FFFFE7A359F3AF5-A',
    typeCreatedAtSortKey: 'PRODUCT-GG-NN-7FFFFE7A359F3AF5-A',
    typePopularitySortKey: 'PRODUCT-GG-NN-7FFFFFFFFFFFFFF0-A',
    typeDiscussedSortKey: 'PRODUCT-GG-NN-7FFFFFFFFFFFFFFF-A',
    listCreatedAtSortKey: 'STREAM-GG-NN-7FFFFE7A359F3AF5-A',
    listPopularitySortKey: 'STREAM-GG-NN-7FFFFFFFFFFFFFFF-A',
    listDiscussedSortKey: 'STREAM-GG-NN-7FFFFFFFFFFFFFFF-A',
    streamCreatedAtSortKey: 'STREAM-GG-NN-7FFFFE7A359F3AF5-A',
    streamPopularitySortKey: 'STREAM-GG-NN-7FFFFFFFFFFFFFFF-A',
    streamDiscussedSortKey: 'STREAM-GG-NN-7FFFFFFFFFFFFFFF-A',
  },
  {
    id: '4',
    shortId: 'p4lhtcl',
    name: 'Lorem ipsum',
    description: {
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. '
        + 'Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s,'
        + ' when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    },
    type: 'PRODUCT',
    state: 'ACTIVE',
    stats: {
      pendingChats: 0,
      talking: 0,
      reach: 0,
      rebounties: 0,
      subBounties: 0,
      sharesSent: 0,
      sharesRecv: 0,
      participantCount: 0,
      pendingResponseCount: 0,
      acceptedResponseCount: 0,
      responseCount: 0,
      commentCount: 0,
      likesCount: 0,
      inviteeCount: 0,
      viewsCount: 15,
    },
    rating: {
      totalStars: 0,
      ratingCount: 0,
      starPointsValue: 1,
    },
    attachments: {
      attachments: {
        test: {
          id: 'test',
          url: placeholderImage,
          displayStyle: 'ATTACH',
          visibility: 'ALL',
          type: 'OTHER',
          meaning: 'PRODUCT',
          mimeType: 'image/png',
          order: '',
          size: 123,
        },
      },
    },
    product: {
      merchant: {
        avatar: '',
        id: '-NGt3YJvQZTTUAYuliCC',
        name: 'Lorem ipsum',
        kind: 'COMPANY',
      },
      merchantId: '-NGt3YJvQZTTUAYuliCC',
      merchantSku: 'sku_1032',
      handle: '1032',
      kind: 'PRODUCT',
      name: 'Lorem ipsum',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. '
        + 'Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s,'
        + ' when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      published: true,
      imageUrl: placeholderImage,
      imageAltText: 'Lorem ipsum',
      variants: {
        sku_1032: {
          sku: 'sku_1032',
          manufacturer: 'Lorem ipsum',
          quantity: 100,
          price: {
            mainPrice: {
              money: {
                amount: '15.00',
                currency: 'USD',
              },
            },
          },
          imageUrl: placeholderImage,
        },
      },
    },
    createdAt: 1668478551867,
    updatedAt: 1674137617674,
    editedAt: 1674137617673,
    postedAt: 1668478552111,
    visibility: 'GLOBAL',
    bountyCategories: {
      '1022:1002': {
        rootCode: '1022',
        categoryCode: '1002',
        categoryName: 'Pre-Rolls',
      },
    },
    creatorUpdatedAtSortKey: '-NGt3YJvQZTTUAYuliCC-GG-NN-7FFFFE7A359F3AF5-A',
    typeCreatedAtSortKey: 'PRODUCT-GG-NN-7FFFFE7A359F3AF5-A',
    typePopularitySortKey: 'PRODUCT-GG-NN-7FFFFFFFFFFFFFF0-A',
    typeDiscussedSortKey: 'PRODUCT-GG-NN-7FFFFFFFFFFFFFFF-A',
    listCreatedAtSortKey: 'STREAM-GG-NN-7FFFFE7A359F3AF5-A',
    listPopularitySortKey: 'STREAM-GG-NN-7FFFFFFFFFFFFFFF-A',
    listDiscussedSortKey: 'STREAM-GG-NN-7FFFFFFFFFFFFFFF-A',
    streamCreatedAtSortKey: 'STREAM-GG-NN-7FFFFE7A359F3AF5-A',
    streamPopularitySortKey: 'STREAM-GG-NN-7FFFFFFFFFFFFFFF-A',
    streamDiscussedSortKey: 'STREAM-GG-NN-7FFFFFFFFFFFFFFF-A',
  },
];

export const MOCK_PRODUCTS:ProductInfo[] = [
  {
    id: '1',
    bountyId: '1',
    merchant: {
      id: '1',
      name: 'Lorem ipsum',
      kind: 'COMPANY',
      avatar: '',
    },
    merchantId: '1',
    productSku: 'sku_1004',
    name: 'Lorem ipsum',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. '
      + 'Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s,'
      + ' when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    imageUrl: placeholderImage,
    price: {
      money: {
        amount: '62.99',
        currency: 'USD',
      },
    },
    addedAt: 1678867412398,
    availableQuantity: 494,
    manufacturer: 'Lorem ipsum',
    quantity: 1,
    balances: {
      'Category 2': {
        key: 'cat2',
        name: 'Category 2',
        amount: 15,
        limit: 15,
        uom: UnitOfMeasurementType.Grams,
        period: {
          type: PeriodType.Last30d,
          startDate: '',
          endDate: '',
        },
      },
    },
  },
  {
    id: '2',
    bountyId: '2',
    merchant: {
      id: '2',
      name: 'Lorem ipsum',
      kind: 'COMPANY',
      avatar: '',
    },
    merchantId: '3',
    productSku: 'sku_1004',
    name: 'Lorem ipsum',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. '
      + 'Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s,'
      + ' when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    imageUrl: placeholderImage,
    price: {
      money: {
        amount: '30.00',
        currency: 'USD',
      },
    },
    addedAt: 1678867412398,
    availableQuantity: 494,
    manufacturer: 'Lorem ipsum',
    quantity: 1,
    balances: {
      'Category 3': {
        key: 'cat3',
        name: 'Category 3',
        amount: 25,
        limit: 15,
        uom: UnitOfMeasurementType.Grams,
        period: {
          type: PeriodType.Last7d,
          startDate: '',
          endDate: '',
        },
      },
    },
  },
  {
    id: 'id 3',
    bountyId: '3',
    merchant: {
      id: '3',
      name: 'Lorem ipsum',
      kind: 'COMPANY',
      avatar: '',
    },
    merchantId: '3',
    productSku: 'sku_1004',
    name: 'Lorem ipsum',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. '
      + 'Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s,'
      + ' when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    imageUrl: placeholderImage,
    price: {
      money: {
        amount: '12.00',
        currency: 'USD',
      },
    },
    addedAt: 1678867412398,
    availableQuantity: 494,
    manufacturer: 'Lorem ipsum',
    quantity: 0,
  },
];

export const MOCK_CART_DETAILS = {
  total: '$101.00',
  subTotal: '$92.99',
  itemsTotal: '$94.99',
  formattedTotal: '$103.00',
  taxes: '$10.00',
  discounts: '$2.00',
  roundingAmount: '$0.01',
  balances: {
    'Category 1': {
      key: 'cat1',
      name: 'Category 1',
      amount: 10,
      limit: 15,
      uom: UnitOfMeasurementType.Grams,
      period: {
        type: PeriodType.Weekly,
        startDate: '',
        endDate: '',
      },
    },
    'Category 2': {
      key: 'cat2',
      name: 'Category 2',
      amount: 15,
      limit: 15,
      uom: UnitOfMeasurementType.Grams,
      period: {
        type: PeriodType.Last30d,
        startDate: '',
        endDate: '',
      },
    },
    'Category 3': {
      key: 'cat3',
      name: 'Category 3',
      amount: 25,
      limit: 15,
      uom: UnitOfMeasurementType.Grams,
      period: {
        type: PeriodType.Last7d,
        startDate: '',
        endDate: '',
      },
    },
  },
};

export const MOCK_PRODUCT_FILTER_1 = [
  {
    id: 'mock_filter_1',
    parentId: '1',
    name: 'Filter 1',
    type: FilterType.MULTI_SELECT,
    options: [
      {
        code: '1',
        name: 'Option 1',
      },
      {
        code: '2',
        name: 'Option 2',
      },
      {
        code: '3',
        name: 'Option 3',
      },
    ],
  },
  {
    id: 'mock_filter_2',
    parentId: '2',
    name: 'Filter 2',
    type: FilterType.MULTI_SELECT,
    options: [
      {
        code: '1',
        name: 'Option 1',
      },
      {
        code: '2',
        name: 'Option 2',
      },
    ],
  },
];

export const MOCK_BREADCRUMB = {
  label: 'Products',
  name: 'products',
};

export const MOCK_CATEGORIES = [
  {
    code: 'category_1',
    name: 'Category 1',
  },
  {
    code: 'category_2',
    name: 'Category 2',
  },
];

export const MOCK_PRODUCT = {
  id: '1',
  shortId: 'p4lhtcl',
  name: 'Lorem ipsum',
  description: {
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. '
      + 'Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s,'
      + ' when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
  },
  type: 'PRODUCT',
  state: 'ACTIVE',
  stats: {
    pendingChats: 0,
    talking: 0,
    reach: 0,
    rebounties: 0,
    subBounties: 0,
    sharesSent: 0,
    sharesRecv: 0,
    participantCount: 0,
    pendingResponseCount: 0,
    acceptedResponseCount: 0,
    responseCount: 0,
    commentCount: 0,
    likesCount: 0,
    inviteeCount: 0,
    viewsCount: 15,
  },
  rating: {
    totalStars: 0,
    ratingCount: 0,
    starPointsValue: 1,
  },
  attachments: {
    attachments: {
      test: {
        id: 'test',
        url: placeholderImage,
        displayStyle: 'ATTACH',
        visibility: 'ALL',
        type: 'OTHER',
        meaning: 'PRODUCT',
        mimeType: 'image/png',
        order: '',
        size: 123,
      },
    },
  },
  product: {
    cdnImageUrl: '',
    chemicalComposition: [
      {
        iconName: 'mir:filter_vintage',
        name: 'CBD',
        value: 5,
        uom: '%',
      },
      {
        iconName: 'mir:filter_vintage',
        name: 'CBD Per Serving',
        value: 5,
        uom: '%',
      },
      {
        iconName: 'mir:spa',
        name: 'THC',
        value: 5,
        uom: '%',
      },
      {
        iconName: 'mir:spa',
        name: 'THC Per Serving',
        value: 5,
        uom: '%',
      },
    ],
    merchant: {
      avatar: '',
      id: '-NGt3YJvQZTTUAYuliCC',
      name: 'Lorem ipsum',
      kind: 'COMPANY',
    },
    merchantId: '-NGt3YJvQZTTUAYuliCC',
    merchantSku: 'sku_1032',
    handle: '1032',
    kind: 'PRODUCT',
    name: 'Lorem ipsum',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. '
      + 'Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s,'
      + ' when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    published: true,
    imageUrl: placeholderImage,
    imageAltText: 'Lorem ipsum',
    variants: {
      sku_1032: {
        sku: 'sku_1032',
        manufacturer: 'Lorem ipsum',
        quantity: 0,
        price: {
          mainPrice: {
            money: {
              amount: '15.00',
              currency: 'USD',
            },
          },
        },
        imageUrl: placeholderImage,
      },
    },
  },
  createdAt: 1668478551867,
  updatedAt: 1674137617674,
  editedAt: 1674137617673,
  postedAt: 1668478552111,
  visibility: 'GLOBAL',
  bountyCategories: {
    '1022:1002': {
      rootCode: '1022',
      categoryCode: '1002',
      categoryName: 'Pre-Rolls',
    },
  },
  creatorUpdatedAtSortKey: '-NGt3YJvQZTTUAYuliCC-GG-NN-7FFFFE7A359F3AF5-A',
  typeCreatedAtSortKey: 'PRODUCT-GG-NN-7FFFFE7A359F3AF5-A',
  typePopularitySortKey: 'PRODUCT-GG-NN-7FFFFFFFFFFFFFF0-A',
  typeDiscussedSortKey: 'PRODUCT-GG-NN-7FFFFFFFFFFFFFFF-A',
  listCreatedAtSortKey: 'STREAM-GG-NN-7FFFFE7A359F3AF5-A',
  listPopularitySortKey: 'STREAM-GG-NN-7FFFFFFFFFFFFFFF-A',
  listDiscussedSortKey: 'STREAM-GG-NN-7FFFFFFFFFFFFFFF-A',
  streamCreatedAtSortKey: 'STREAM-GG-NN-7FFFFE7A359F3AF5-A',
  streamPopularitySortKey: 'STREAM-GG-NN-7FFFFFFFFFFFFFFF-A',
  streamDiscussedSortKey: 'STREAM-GG-NN-7FFFFFFFFFFFFFFF-A',
} as Bounty;
