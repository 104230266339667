import {
  Container, Grid, Box, useTheme, Typography,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { isNil } from 'lodash';
import {
  useContext, Suspense, lazy, useMemo, FC,
} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import BreadcrumbsNavigation from 'components/BreadcrumbsNavigation';
import EmptyState from 'components/EmptyState';
import ProductCardSkeleton from 'components/ProductCard/index.skeleton';
import SubheaderWrapper from 'components/SubheaderWrapper';
import UnstyledLink from 'components/UnstyledLink';
import Categories from 'containers/Categories';
import ProductFilters from 'containers/ProductFilters';
import ProductSort from 'containers/ProductSort';
import PromotionBanner from 'containers/PromotionBanner';
import SearchProducts from 'containers/SearchProducts';
import { CartDispatchContext } from 'context/CartContext';
import { CategoryContext } from 'context/CategoryContext';
import { LocationContext } from 'context/LocationContext';
import { SystemContext } from 'context/SystemContext';

import { useBreadcrumbs } from 'hooks/useBreadcrumbs';
import useFilters from 'hooks/useFilters';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { getProductDetailsRoute } from 'hooks/useRouting';

import { BannerType, ProductSortOptions, ViewMode } from 'constants/enums';
import { Bounty } from 'types/bounty.interface';
import { Promotion } from 'types/promotion.interface';
import { shouldUseProductWeight } from 'utils/productUtils';

const ProductCard = lazy(() => import('components/ProductCard'));

interface ProductsProps {
  promotions?: Record<BannerType, Promotion[]>;
  onLoadProducts: () => void;
  skeletonProducts: number[];
  products: Bounty[] | null;
  hasMore: boolean;
  isLoading: boolean;
  isLoadingMore: boolean;
  onSort: (newSort: ProductSortOptions) => void;
  sortOption: ProductSortOptions;
}
const Products: FC<ProductsProps> = ({
  promotions,
  onLoadProducts,
  skeletonProducts,
  products,
  hasMore,
  isLoadingMore,
  isLoading,
  onSort,
  sortOption,
}) => {
  const theme = useTheme();
  const matchesScreenSizeMd = useMediaQuery(theme.breakpoints.down('md'));
  const matchesScreenSizeSm = useMediaQuery(theme.breakpoints.down('sm'));

  const { addItem, addItemWithWeight } = useContext(CartDispatchContext);
  const { categories } = useContext(CategoryContext);
  const { shopSettings } = useContext(SystemContext);
  const { onboardingInfo } = useContext(LocationContext);
  const { getProductsBreadcrumbs } = useBreadcrumbs();
  const [searchParams] = useSearchParams();
  const {
    isLoading: areFiltersLoading, activeFilters, filters, ...remainingFilterProps
  } = useFilters();
  const hasFilters = useMemo(() => areFiltersLoading || filters?.length > 0, [areFiltersLoading, filters]);
  const breadcrumbs = useMemo(
    () => getProductsBreadcrumbs(activeFilters, filters),
    [categories, activeFilters, filters],
  );
  const isDescriptionVisible = shopSettings?.viewConfigs?.PRODUCT_DESCRIPTION?.viewMode === ViewMode.Full;
  const searchFilter = searchParams?.get('filter.q') || '';
  const heroes = promotions?.[BannerType.Hero];

  const { handleTrackAddItemToCart, handleTrackViewItem } = useGoogleAnalytics();

  const addToCard = (bounty: Bounty) => {
    if (shouldUseProductWeight(bounty?.product)) {
      const weight = bounty?.product?.sortWeight?.toString() || '';
      addItemWithWeight({
        bounty,
        weight,
        onSuccess: () => {
          toast.success('Product added to cart!');
          handleTrackAddItemToCart({ bounty, weight });
        },
      });
    } else {
      addItem({
        bounty,
        quantity: 1,
        onSuccess: () => {
          toast.success('Product added to cart!');
          handleTrackAddItemToCart({ bounty, selectedQuantity: 1 });
        },
      });
    }

    handleTrackViewItem(bounty);
  };

  return (
    <Box position="relative">
      <SubheaderWrapper>
        <Box display="flex" gap={1} alignItems="center">
          {matchesScreenSizeSm ? (
            <>
              <Categories />
              <SearchProducts searchValue={searchFilter} />
            </>
          ) : (
            <>
              <BreadcrumbsNavigation breadcrumbs={breadcrumbs} />
              {categories?.length > 0 && (
                <>
                  <Typography variant="subheader" fontWeight={600}>
                    /
                  </Typography>
                  <Categories />
                </>
              )}
              <SearchProducts searchValue={searchFilter} />
            </>
          )}
        </Box>
      </SubheaderWrapper>

      <Container>
        {(heroes && heroes?.length > 0) && <PromotionBanner promotions={heroes} />}

        <Grid container spacing={2}>
          {hasFilters && !matchesScreenSizeMd && (
            <Grid item xs={12} md={3}>
              <ProductFilters
                {...remainingFilterProps}
                isLoading={areFiltersLoading}
                filters={filters}
                activeFilters={activeFilters}
              />
            </Grid>
          )}

          <Grid item xs={12} md={hasFilters ? 9 : 12}>
            {!isLoading && isNil(products)
              ? (
                <EmptyState message="No products found!" />
              ) : (
                <>
                  <Grid container spacing={1} mb={2}>
                    {hasFilters && matchesScreenSizeMd && (
                      <Grid item xs={4} sm={4}>
                        <ProductFilters
                          {...remainingFilterProps}
                          isLoading={areFiltersLoading}
                          filters={filters}
                          activeFilters={activeFilters}
                        />
                      </Grid>
                    )}
                    <Grid item xs={hasFilters ? 8 : 12} sm={hasFilters ? 8 : 12} md={12}>
                      <ProductSort value={sortOption} onChange={onSort} />
                    </Grid>
                  </Grid>
                  <InfiniteScroll
                    pageStart={0}
                    loadMore={onLoadProducts}
                    hasMore={hasMore}
                  >
                    <Grid container spacing={2}>
                      {!isLoading && products && products.map((bounty, index) => (
                        <Grid key={`${index}-${bounty.id}`} item xs={12} sm={6} md={6} lg={4}>
                          <Suspense fallback={<ProductCardSkeleton />}>
                            <UnstyledLink
                              to={getProductDetailsRoute(bounty.shortId)}
                              height="100%"
                              width="100%"
                            >
                              <ProductCard
                                bounty={bounty}
                                onAdd={addToCard}
                                hasAddButton
                                isDescriptionVisible={isDescriptionVisible}
                                store={onboardingInfo?.store}
                              />
                            </UnstyledLink>
                          </Suspense>
                        </Grid>
                      ))}

                      {(isLoading || isLoadingMore) && skeletonProducts.map((loader) => (
                        <Grid key={`productSkeleton-${loader}`} item xs={12} sm={6} md={6} lg={4}>
                          <ProductCardSkeleton />
                        </Grid>
                      ))}
                    </Grid>
                  </InfiniteScroll>
                </>
              )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Products;
