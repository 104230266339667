import { Box, Typography } from '@mui/material';
import { FC, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CustomButton from 'components/CustomButton';
import IncrementWrapper from 'components/IncrementWrapper';
import { CartContext, CartDispatchContext } from 'context/CartContext';

import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';

import { Bounty } from 'types/bounty.interface';
import { getTopLevelVariant } from 'utils/priceUtils';
import { getRealQuantity } from 'utils/productUtils';

interface KioskQuantitySelectorProps {
  bounty: Bounty;
  setShowConfirmation: () => void;
  isLoading?: boolean;
  submitLabel?: string;
  cancelLabel?: string;
  onCancel?: () => void;
}

const KioskQuantitySelector:FC<KioskQuantitySelectorProps> = ({
  bounty,
  setShowConfirmation,
  isLoading,
  submitLabel = 'Add to Cart',
  cancelLabel = 'Cancel',
  onCancel,
}) => {
  const cart = useContext(CartContext);
  const navigate = useNavigate();
  const { addItem } = useContext(CartDispatchContext);
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const { product } = bounty;
  const { quantity } = getTopLevelVariant(product) || {};
  const productQuantity = Math.max(0, quantity || 0);
  const realQuantity = getRealQuantity(quantity, bounty.id, cart);
  const showRemainingStock = realQuantity > 0 && realQuantity <= 10;

  const { handleTrackAddItemToCart } = useGoogleAnalytics();

  const decreaseQuantity = () => {
    if (isLoading) {
      return;
    }

    setSelectedQuantity((prevQuantity) => (prevQuantity > 1 ? selectedQuantity - 1 : 1));
  };

  const increaseQuantity = () => {
    if (isLoading) {
      return;
    }

    setSelectedQuantity((prevQuantity) => (prevQuantity === realQuantity ? selectedQuantity : selectedQuantity + 1));
  };

  const handleAddToCart = () => {
    addItem({
      bounty,
      quantity: selectedQuantity,
      onSuccess: () => {
        setSelectedQuantity(1);
        handleTrackAddItemToCart({ bounty, selectedQuantity });
        setShowConfirmation();
      },
    });
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <Box width="100%">
      {realQuantity > 0 && (
        <IncrementWrapper
          onDecrease={decreaseQuantity}
          onIncrease={increaseQuantity}
        >
          <Typography p={1} textAlign="center" flexGrow={1}>
            {selectedQuantity}
          </Typography>
        </IncrementWrapper>
      )}

      {showRemainingStock && (
        <Typography variant="body2" fontWeight={600} color="error" mt={2}>
          Only
          {' '}
          {realQuantity}
          {' '}
          left in stock!
        </Typography>
      )}

      <Box display="flex" gap={1} width="100%" mt={2}>
        <CustomButton
          id="button-cancel"
          variant="outlined"
          fullWidth
          onClick={onCancel || handleCancel}
        >
          {cancelLabel}
        </CustomButton>
        <CustomButton
          disabled={isLoading || productQuantity <= 0}
          id="button-addProduct"
          fullWidth
          onClick={handleAddToCart}
        >
          {submitLabel}
        </CustomButton>
      </Box>
    </Box>
  );
};

export default KioskQuantitySelector;
