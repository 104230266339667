import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import NumberFormatField from 'components/NumberFormatField';

export const WeightField = styled(
  NumberFormatField,
  { shouldForwardProp: (props) => props !== 'kioskMode' },
)<{ kioskMode: boolean }>(({ theme, kioskMode }) => ({
  '& fieldset': {
    ...(kioskMode
      ? {
        borderRadius: 0,
        border: 0,
      } : {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: '8px',
        borderBottomLeftRadius: '8px',
        border: `1px solid ${theme.palette.border.main}`,
      }
    ),
  },
  maxWidth: kioskMode ? '100%' : '90px',

  '& input': {
    textAlign: kioskMode ? 'center' : 'initial',
  },

  [theme.breakpoints.down('sm')]: {
    maxWidth: kioskMode ? '100%' : '60px',
  },
}));

export const Unit = styled(Box)(({ theme }) => ({
  height: '40px',
  padding: theme.spacing(0, 1.5),
  backgroundColor: theme.palette.background.secondary,
  borderTopRightRadius: '8px',
  borderBottomRightRadius: '8px',
  display: 'flex',
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 1),
  },
}));
